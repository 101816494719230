@import "../../variables.scss"; 

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    justify-content: center;
    background-color: $header;
    padding-left: 10px;
    padding-right: 10px;

    @media only screen and (max-width: 600px) {
        height: 100px;
        z-index: 1;
    }

    .infoContainer {
        display: flex;
        justify-content: flex-end;

        .infoButton {
            padding: 5px;
            color: #9e9e9e;
            height: 20px;
            overflow: visible;     
            text-align: center;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 50%;
    
            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.04);
            }
            svg {
                font-size: 20px;
            }
        }
    }
    

    .title {
        font-size: 24px;
        color: $fontColor;
        text-transform: uppercase;
        width: 100%;

        @media only screen and (max-width: 600px) {
            font-size: 16px;
        }
    }
}