
.details {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    max-height: 100%;
    box-sizing: border-box;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    .title {
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;

        @media only screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    .content {
        font-size: 14px;
        text-align: justify;
        padding-top: 20px;
    }

    .galleryContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;

        .carousel {
            padding: 3px;
            max-height: 230px;
            
            li {
                background-color: white !important;

                img {
                    max-height: 230px;
                    object-fit: contain;
                }
            }            
        }
    }
}
