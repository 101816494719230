.cursor {
    background-image: url("data:image/svg+xml,%3Csvg width='1.5em' height='1.5em' viewBox='0 0 16 16' class='bi bi-caret-down-fill' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    top: -3px;
    left: 25px;
    cursor: grab;
}