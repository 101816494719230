@import "../../variables.scss"; 

.timelineBar {
    width: 100%;
    height: 70px;
    margin: 0 auto;
    background-color: $timeline;//rgba(250, 235, 215, 0.7);

    .trackWrapper {
        width: 100%;
        height: 70px;
        display: flex;
        position: relative;
    
        .track {
            padding-left: 30px;
            padding-right: 30px;
            list-style-type: none;
            width: 100%;
            display:flex;
            justify-content: space-between;

            .year {
                float: left;
                border-right: 1px solid #b3b3b3;
                height: 13px;
                line-height: 40px;
                width: 0px;
                position: relative;

                .yearLabel {
                    position: absolute;
                    left: -15px;
                    top: 10px;
                }
            }
        }

    }
}