.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .content {
        display: flex;
        flex-direction: row;
        height: calc(100% - 70px - 50px);
        flex: 1;
        justify-content: center;

        
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
}
