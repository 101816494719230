.map {
    width: 50%;
    display:flex;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    .image {
        height: fit-content;
        display: block;
        width: fit-content;

        img {      
            &:first-of-type {
                height: auto;
                max-height: calc(100vh - 70px - 50px);
            }
        }
    }
}